/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

interface ImageDataType {
	images: {
		fallback: {
			src: string;
		};
	};
	width: number;
	height: number;
}

interface Props {
	title: string;
	description?: string;
	lang?: string;
	meta?: JSX.IntrinsicElements['meta'][];
	featuredImage?: ImageDataType;
	markupSchema?: any;
	articleImage?: string;
}

const Seo: React.FC<Props> = (props: Props) => {
	const { description = '', lang = 'en-au', meta: _meta = [], title, markupSchema } = props;
	const location = useLocation();

	const { site, featuredImage } = useStaticQuery<GatsbyTypes.SeoMetaDataQuery>(
		graphql`
			query SeoMetaData {
				site {
					siteMetadata {
						title
						description
						siteUrl
						og {
							siteName
							twitterCreator
						}
					}
				}
				featuredImage: file(absolutePath: { glob: "**/src/images/physiotherapist-link.jpg" }) {
					childImageSharp {
						gatsbyImageData(layout: FIXED)
					}
				}
			}
		`,
	);

	const ogImage =
		props.featuredImage ?? ((featuredImage?.childImageSharp?.gatsbyImageData as unknown) as ImageDataType);

	const articleOgImage = props.articleImage;

	const metaTitle = title ?? site?.siteMetadata?.title;
	const metaDescription = description ?? site?.siteMetadata?.description;

	let meta: JSX.IntrinsicElements['meta'][] = [
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:title`,
			content: metaTitle,
		},
		{
			property: `og:description`,
			content: metaDescription,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			property: 'og:image',
			content: articleOgImage || `${site?.siteMetadata?.siteUrl}${ogImage.images.fallback.src}`,
		},
		{
			property: 'og:image:width',
			content: `${ogImage.width}`,
		},
		{
			property: 'og:image:height',
			content: `${ogImage.height}`,
		},
		{
			property: 'og:url',
			content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
		},
		{
			name: `twitter:card`,
			content: `summary_large_image`,
		},
		{
			name: `twitter:title`,
			content: title,
		},
		{
			name: `twitter:description`,
			content: metaDescription,
		},
		{
			name: 'twitter:image',
			content: articleOgImage || `${site?.siteMetadata?.siteUrl}${ogImage.images.fallback.src}`,
		},
		{
			name: 'twitter:creator',
			content: site?.siteMetadata!.og!.twitterCreator,
		},
	];

	meta = meta.concat(_meta);

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			meta={meta}
		>
			{markupSchema && <script type='application/ld+json'>{JSON.stringify(markupSchema)}</script>}
		</Helmet>
	);
};

export default Seo;
